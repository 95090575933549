/*--------------------------------------------------------------
## Basic Color
----------------------------------------------------------------*/
:root {
  --accent: #3ff0f0;
}
$white: #fff;
$black: #161616;
$primary: #fefefe;
$secondary: rgba($primary, 0.7);
$ternary: #999696;
$border: #4d4d4d;
$gray: #181818;
$accent: var(--accent);
